import { App as CapacitorApp } from '@capacitor/app'
import moment from 'moment'
import { IonContent } from '@ionic/react'
import { ScreenOrientation } from '@capacitor/screen-orientation'
import { useTranslation } from 'react-i18next'
import {
  getAuth, onAuthStateChanged, browserLocalPersistence, setPersistence
} from 'firebase/auth'
import { useEffect } from 'react'
import useAuthStore from '../hooks/use-auth'
import client from '../utils/client'
import AuthenticatedRoutes from './authenticated-routes'
import { useNavigate } from '../hooks/use-navigate'
import { isNativeMobile } from '../utils/ionic'
import MobileHeader from '../mobile/header'
import UnauthenticatedRoutes from './unauthenticated-routes'
import MobileFooter from '../mobile/footer-menu'
import useStyles from './styles'

function App() {
  const {
    isAuthenticated, onLogin, logout, initialLoading
  } = useAuthStore()
  const auth = getAuth()
  const { t, i18n } = useTranslation()
  const { goTo } = useNavigate()
  const classes = useStyles()

  useEffect(() => {
    moment.locale(i18n.language)
    if (isNativeMobile()) {
      ScreenOrientation.lock({ orientation: 'portrait' })
    }
  }, [])

  useEffect(() => {
    useAuthStore.setState({ goTo })

    // Sets auth persistence (browserLocalPersistence) only for web apps (not mobile).
    if (!isNativeMobile()) {
      setPersistence(auth, browserLocalPersistence).catch(() => {})
    }

    // If user is logged in fetches and stores an ID token (forces refresh)
    // Sets the token in API client headers (client.defaults.headers.common).
    // Calls onLogin() to update the authentication state.
    // If user is not logged in, triggers a logout.
    try {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const token = await user.getIdToken(true)
            client.defaults.headers.common = { Authorization: `${token}` }
            useAuthStore.setState({ manualAuth: false })
            onLogin(user, t('Verification_email_sent'), false)

            if (window.authStateCheckInterval) {
              window.clearInterval(window.authStateCheckInterval as any)
              window.authStateCheckInterval = undefined
            }
          } catch (error: any) {
            if (error.code === 'auth/network-request-failed') {
              // retries fetching the token after 5 seconds if back online
              setTimeout(async () => {
                if (navigator.onLine) {
                  try {
                    await getAuth().currentUser?.getIdToken(true)
                  } catch { /* could add error toasts for these catches */ }
                }
              }, 5000)
            } else {
              logout()
            }
          }
        } else {
          logout()
        }
      })
    } catch (error) {
      logout()
    }
  }, [initialLoading, isAuthenticated])

  // Handle app state changes. When the app comes back to the foreground
  // if the device is online, attempts to refresh the Firebase auth token.
  // If offline, skips token refresh to avoid unnecessary errors.
  useEffect(() => {
    CapacitorApp.addListener('appStateChange', async (state) => {
      if (state.isActive) {
        if (navigator.onLine) {
          try {
            await getAuth().currentUser?.getIdToken(true)
          } catch { /* need to add toastify for errors */ }
        }
      }
    })
  }, [])

  if (initialLoading) return null

  if (!isAuthenticated) {
    return (
      <>
        <UnauthenticatedRoutes />
        <div className={classes.background} />
      </>
    )
  }

  return (
    <>
      {isNativeMobile() && <MobileHeader />}
      <IonContent>
        <AuthenticatedRoutes />
        <div className={classes.background} />
      </IonContent>
      {isNativeMobile() && <MobileFooter />}
    </>
  )
}

export default App
