import { ReactNode } from 'react'
import Dialog from '@mui/material/Dialog'
import { Paper } from '@mui/material'
import { useTheme } from 'react-jss'

interface Props {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
}

function Modal({ open, onClose, children = null }: Props) {
  const theme: any = useTheme()

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <Paper
        style={{
          backgroundColor: theme.primary.boxBackground,
          overflow: 'hidden',
          color: theme.primary.textColor
        }}
      >
        <div>{children ?? null}</div>
      </Paper>
    </Dialog>
  )
}

Modal.defaultProps = {
  children: null
}

export default Modal
