import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme: any) => ({
  container: {
    padding: 15
  },

  dates: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },

  date: {
    padding: 13,
    border: `1px solid ${theme.grey.grey5}`,
    borderRadius: 5
  },

  datepickerPopper: {
    zIndex: 9999
  },

  break: {
    borderTop: `1px solid ${theme.grey.grey3}`,
    marginTop: 30,
    marginBottom: 30
  }
}))

export default useStyles
