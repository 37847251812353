import app from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

// Prevent Firebase initialization in a test environment
const isTestEnv = process.env.NODE_ENV === 'test'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY || 'test-api-key',
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN || 'test-auth-domain',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL || 'test-database-url',
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID || 'test-project-id',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET || 'test-storage-bucket',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID || 'test-messaging-sender-id',
  appId: process.env.REACT_APP_FIREBASE_APPID || 'test-app-id'
}

const firebaseApp = !isTestEnv ? app.initializeApp(config) : null
const firebaseAuth = firebaseApp ? app.auth() : null
const firebase = firebaseApp ? app.auth : null

export { firebaseAuth, firebase }
