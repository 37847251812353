import { useNavigate } from "../../../hooks/use-navigate";
import { Domain, getDomain } from "../../../utils/get-domain";
import useStyles from "./styles";

const TopBarLogo = () => {
  const classes = useStyles();
  const domain = getDomain();
  const { goTo } = useNavigate();

  switch (domain) {
    case Domain.SNXT:
      return (
        <svg
          width="200"
          height="50"
          viewBox="0 0 200 50"
          xmlns="http://www.w3.org/2000/svg"
          className={classes.logo}
          onClick={() => goTo("/sites")}
          data-testid="navMenuLogo"
          role="img"
          aria-label="SonneNEXT Logo"
        >
          <title>SonneNEXT Logo</title>
          <text x="-50" y="45" fontSize="58" fontFamily="Arial, sans-serif" fill="black" text-anchor="start">
            <tspan fill="#F39205">AI</tspan>·LEEN
          </text>
        </svg>
      );

    default:
      return (
        <img
          src={"/images/PARIS INSIGHTS - LONG.svg"}
          className={classes.logo}
          onClick={() => goTo("/sites")}
          data-testid="navMenuLogo"
          alt={"Joulen Logo"}
        />
      );
  }
};

export default TopBarLogo;
